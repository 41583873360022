import WindowDialog from "./WindowDialog";
import { shellDoc } from "../inlined";

const blob = URL.createObjectURL(new Blob([shellDoc], { type: "text/html", endings: "native" }));

const inspect = async() => {
	const frame = document.createElement("embed");
	frame.setAttribute("type", "text/plain");
	frame.setAttribute("width", "1024");
	frame.setAttribute("height", "768");
	frame.setAttribute("src", blob);

	await new WindowDialog({
		title: "Debug Shell",
		frame
	}).show();
};

export default inspect;

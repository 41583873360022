import AlertDialog from "./AlertDialog";

const prompt = (message?: string, defaultValue?: string, title?: string, placeholder?: string): Promise<string | null> => {
	return new Promise(resolve => {
		const input = document.createElement("input");
		input.type = "text";
		input.value = defaultValue || "";
		input.placeholder = placeholder || "";
		input.style.marginBottom = "4px"

		const dialog = new AlertDialog({
			message: message,
			title: title,
			controls: "close-only",
			elements: [input],
			buttons: [
				{
					text: "Cancel",
					click: () => {
						dialog.cancel();
						resolve(null);
					}
				},
				{
					text: "OK",
					click: () => {
						dialog.dismiss();
						resolve(input.value);
					}
				}
			]
		});

		input.onkeydown = (e) => {
			if (e.keyCode == 13) { // Enter
				e.preventDefault();
				dialog.buttons![0].click!();
			}
		};
		dialog.onclose = () => resolve(null);
		dialog.show();
	});
};

export default prompt;

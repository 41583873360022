import AlertDialog from "./AlertDialog";

const confirm = (message?: string, title?: string): Promise<boolean> => {
	return new Promise(resolve => {
		const dialog = new AlertDialog({
			message: message,
			title: title,
			controls: "close-only",
			buttons: [
				{
					text: "Cancel",
					click: () => {
						dialog.cancel();
						resolve(false);
					}
				},
				{
					text: "OK",
					click: () => {
						dialog.dismiss();
						resolve(true);
					}
				}
			]
		});
		dialog.onclose = () => resolve(false);
		dialog.show();
	});
};

export default confirm;

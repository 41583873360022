import WindowDialog from "./WindowDialog";

const popup = async (src?: FrameInit, title?: string) => {
	await new WindowDialog({
		title,
		frame: src
	}).show();
};

export default popup;

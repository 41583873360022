import { fallback, RawObject, define } from "./util";
import { dialogDoc, dialogCss, msSansSerif, msSansSerifBold } from "../inlined";

const docUrl = URL.createObjectURL(new Blob([dialogDoc], { type: "application/xhtml+xml", endings: "native" }));
const cssUrl = URL.createObjectURL(new Blob([dialogCss], { type: "text/css", endings: "native" }));

const fontStyleSheet = `@font-face {
	font-family: "ms-sans-serif";
	font-style: normal;
	font-weight: normal;
	font-stretch: normal;
	src: url("${URL.createObjectURL(new Blob([msSansSerif], { type: "font/woff2", endings: "native" }))}") format("truetype");
}

@font-face {
	font-family: "ms-sans-serif";
	font-style: normal;
	font-weight: bold;
	font-stretch: normal;
	src: url("${URL.createObjectURL(new Blob([msSansSerifBold], { type: "font/woff2", endings: "native" }))}") format("truetype");
}`;

function waitForBody(): Promise<HTMLElement> {
	return new Promise(resolve => {
		const body = document.body;
		if (body != null) {
			resolve(body);
			return;
		}

		document.addEventListener("DOMContentLoaded", () => resolve(document.body), { once: true, passive: true });
	});
}

function createFrame() {
	const frame = document.createElement("iframe");
	frame.setAttribute("type", "text/plain");
	frame.setAttribute("width", "1024");
	frame.setAttribute("height", "768");
	frame.setAttribute("scrolling", "no");
	frame.setAttribute("loading", "eager");
	frame.setAttribute("allowfullscreen", "true");
	frame.setAttribute("allowtransparency", "true");
	frame.setAttribute("fetchpriority", "high");
	frame.setAttribute("style", "position:absolute;display:block;width:100%;height:100%;top:0px;left:0px;right:0px;bottom:0px;border:none;z-index:9999;");
	return frame;
}

export default class Dialog extends RawObject implements globalThis.Dialog {
	declare readonly width: int | nul;
	declare readonly height: int | nul;
	declare readonly x: int | nul;
	declare readonly y: int | nul;
	declare readonly centered: bool | nul;
	declare readonly draggable: bool | nul;
	declare readonly resizable: bool | nul;
	declare readonly title: string | nul;
	declare readonly controls: "normal" | "close-only" | "close-with-help" | "none" | nul;
	declare readonly help: AnyFunc | nul;

	// current states
	readonly canceled: bool = false;
	readonly maximized: bool = false;
	readonly minimized: bool = false;

	onclose: AnyFunc | nul;

	// related elements
	protected currentFrame: HTMLIFrameElement | nul;
	protected frameWindow: Window | nul;
	protected frameDocument: Document | nul;
	protected dialogElement: HTMLElement | nul;

	constructor(init?: DialogInit) {
		super(init);
	}

	protected async initializeHeader(header: HTMLElement, win: Window, doc: Document) {
		const title = this.title;
		if (title != null) {
			const elem = doc.createElement("div");
			elem.id = "title";
			elem.textContent = title;
			header.appendChild(elem);
		}

		const close = doc.createElement("button");
		close.id = "close-button";
		close.title = "Close";
		close.onclick = () => this.close();

		const help = doc.createElement("button");
		help.id = "help-button";
		help.title = "Help";
		help.onclick = () => this.help?.apply(this, []);

		const maximize = doc.createElement("button");
		maximize.id = "maximize-button";
		maximize.title = "Maximize";
		maximize.onclick = () => this.maximize();

		const minimize = doc.createElement("button");
		minimize.id = "minimize-button";
		minimize.title = "Minimize";
		minimize.onclick = () => this.minimize();

		switch (this.controls) {
			case "none":
				break;
			case "close-only":
				header.appendChild(close);
				break;
			case "close-with-help":
				header.appendChild(close);
				header.appendChild(help);
				break;
			default:
				header.appendChild(close);
				header.appendChild(maximize);
				header.appendChild(minimize);
				break;
		}
	}

	protected async initializeBody(body: HTMLElement, win: Window, doc: Document) {
	}

	protected draggableElement(elem: HTMLElement, dragger = elem) {
		dragger.onmousedown = (e) => {
			e.preventDefault();
			let x1 = e.clientX;
			let y1 = e.clientY;

			elem.onmouseout = elem.onmouseup = (e) => {
				e.preventDefault();
				elem.onmouseup = null;
				elem.onmousemove = null;
			};

			elem.onmousemove = (e) => {
				e.preventDefault();
				const x = e.clientX;
				const y = e.clientY;

				let x2 = x1 - x;
				let y2 = y1 - y;
				x1 = x;
				y1 = y;

				elem.style.top = Math.round(elem.offsetTop - y2) + "px";
				elem.style.left = Math.round(elem.offsetLeft - x2) + "px";
			};
		};
	}

	protected async measureDocument(dialog: HTMLElement, header: HTMLElement, frame: HTMLIFrameElement) {
		dialog.style.width = (this.width || 250) + "px";

		const height = this.height;
		if (height != null)
			dialog.style.height = height + "px";

		if (fallback(this.centered, true)) {
			const resize = () => {
				const dw = dialog.clientWidth + 6; // includes border paddings
				const dh = dialog.clientHeight + 6;

				dialog.style.left = Math.floor((frame.clientWidth - dw) / 2) + "px";
				dialog.style.top = Math.floor((frame.clientHeight - dh) / 2) + "px";
			};

			resize();
			dialog.addEventListener("resize", resize, { capture: false, passive: true });
			window.addEventListener("resize", resize, { capture: false, passive: true });
		} else {
			const x = this.x;
			if (x != null)
				dialog.style.left = x + "px";

			const y = this.y;
			if (y != null)
				dialog.style.top = y + "px";
		}

		if (fallback(this.draggable, true)) {
			this.draggableElement(dialog, header);
		}

		if (fallback(this.resizable, false)) {
			dialog.style.resize = "both";
			dialog.style.overflow = "auto";
		}

		dialog.style.visibility = "visible";
	}

	async show() {
		const frame = createFrame();
		const body = await waitForBody();

		frame.setAttribute("src", docUrl);
		body.scrollTo(0, 0);
		body.style.overflow = "hidden";
		body.appendChild(frame);
		await new Promise(resolve => frame.addEventListener("load", resolve, { once: true, passive: true }));

		const win = frame.contentWindow!;
		const doc = win.document;
		const head = doc.head;

		// load stylesheet
		const link = doc.createElement("link");
		link.rel = "stylesheet";
		link.type = "text/css";
		link.href = cssUrl;
		head.appendChild(link);

		// set base url
		const base = doc.createElement("base");
		base.href = window.origin;
		head.appendChild(base);

		// load fonts
		const style = doc.createElement("style");
		style.type = "text/css";
		style.textContent = fontStyleSheet;
		head.appendChild(style);

		const dialog = doc.getElementById("dialog")!;
		const header = doc.getElementById("titlebar")!;

		this.frameWindow = win;
		this.frameDocument = doc;
		this.currentFrame = frame;
		this.dialogElement = dialog;

		await this.initializeHeader(header, win, doc);
		await this.initializeBody(doc.getElementById("dialog-body")!, win, doc);
		await this.measureDocument(dialog, header, frame);

		doc.body.onclick = (e) => {
			e.preventDefault();
			header.setAttribute("inactive", "true");
		};
		dialog.onclick = (e) => {
			e.preventDefault();
			e.stopPropagation();
			header.removeAttribute("inactive");
		};
	}

	dismiss() {
		if (this.currentFrame != null) {
			document.body.style.overflow = "";
			this.currentFrame.remove();
			this.currentFrame = null;
		}
	}

	cancel() {
		this.dismiss();
		define(this, "canceled", true, false);
	}

	close() {
		this.dismiss();
		this.onclose?.apply(void 0, []);
	}

	maximize() {
		const dialog = this.dialogElement;
		const button = this.frameDocument?.getElementById("maximize-button");
		const width = this.width;
		const height = this.height;

		if (dialog != null) {
			if (this.maximized) {
				dialog.style.width = width != null ? width + "px" : "";
				dialog.style.height = height != null ? height + "px" : "";
				dialog.style.left = "";
				dialog.style.top = "";
				define(this, "maximized", false);
				if (button != null) {
					button.removeAttribute("restore");
					button.title = "Maximize";
				}
			} else {
				dialog.style.width = "100%";
				dialog.style.height = "100%";
				dialog.style.left = "-3px";
				dialog.style.top = "-3px";
				define(this, "maximized", true);
				if (button != null) {
					button.setAttribute("restore", "true");
					button.title = "Restore";
				}
			}
		}
	}

	minimize() {
		this.dismiss();
		define(this, "minimized", true, false);
	}
}

import AlertDialog, { initElement } from "./AlertDialog";
import { fallback } from "./util";

const form = (message?: string, title?: string, elementsInit?: FormElements): Promise<FormResults | null> => {
	return new Promise(resolve => {
		const elements = elementsInit || {};
		const results: FormResults = {};
		const htmlElements: HTMLElement[] = [];
		for (let id in elements) {
			const element = elements[id];
			const elemId = "xnnxz-" + id; // add a prefix to avoid duplicates
			const row = document.createElement("div");
			row.className = "row";

			if ("element" in element) {
				const elem = initElement(element.element || { tagName: "div" });
				elem.setAttribute("id", elemId);

				const label = document.createElement("label");
				label.textContent = element.label || id;
				label.setAttribute("for", elemId);

				if (fallback(element.stack, true)) {
					label.style.display = "block";
				}

				if (element.beforeLabel) {
					row.appendChild(elem);
					row.appendChild(label);
				} else {
					row.appendChild(label);
					row.appendChild(elem);
				}
				results[id] = elem;
			} else {
				const elem = initElement(element);
				elem.setAttribute("id", elemId);

				const label = document.createElement("label");
				label.textContent = id;
				label.setAttribute("for", elemId);

				row.appendChild(label);
				row.appendChild(elem);
				results[id] = elem;
			}

			htmlElements.push(row);
		}

		const dialog = new AlertDialog({
			message,
			title,
			elements: htmlElements,
			buttons: [
				{
					text: "Cancel",
					click: () => {
						dialog.cancel();
						resolve(null);
					}
				},
				{
					text: "OK",
					click: () => {
						dialog.dismiss();
						resolve(results);
					}
				}
			]
		});
		dialog.onclose = () => resolve(null);
		dialog.show();
	});
};

export default form;

import Dialog from "./Dialog";
import AlertDialog from "./AlertDialog";
import WindowDialog from "./WindowDialog";
import alert from "./alert";
import confirm from "./confirm";
import prompt from "./prompt";
import block from "./block";
import form from "./form";
import popup from "./popup";
import inspect from "./inspect";
import { define } from "./util";

const webAlert : WebAlert = {
	Dialog,
	AlertDialog,
	WindowDialog,
	alert,
	confirm,
	prompt,
	block,
	form,
	popup,
	inspect
};

// unchecked global cast
const __global = window as any;
define(__global, "webAlert", webAlert, false);

delete __global.alert;
delete __global.confirm;
delete __global.prompt;

define(__global, "alert", alert, false);
define(__global, "confirm", confirm, false);
define(__global, "prompt", prompt, false);
define(__global, "block", block, false);
define(__global, "form", form, false);
define(__global, "popup", popup, false);
define(__global, "inspect", inspect, false);

import Dialog from "./Dialog";
import { fallback } from "./util";

function getSourceString(init: SourceInit) {
	if (typeof init == "string")
		return init;
	else if (init instanceof URL)
		return init.href;
	else {
		const arr = new Uint8Array(init);
		return URL.createObjectURL(new Blob([arr.buffer], { type: "text/html", endings: "native" }));
	}
}

export default class WindowDialog extends Dialog implements globalThis.WindowDialog {
	declare readonly frame: FrameInit | nul;

	constructor(init?: WindowDialogInit) {
		const config = init || {};
		super({
			...config,
			centered: fallback(config.centered, false),
			draggable: fallback(config.draggable, true),
			width: config.width || 1024,
			height: config.height || 768,
		});
	}

	protected async initializeBody(body: HTMLElement, win: Window, doc: Document) {
		body.remove();
		const dialog = this.dialogElement!;

		const frame = this.frame || "";
		if (frame instanceof HTMLElement) {
			frame.className = "frame";
			dialog.appendChild(frame);
			return;
		}

		const elem = document.createElement("embed");
		elem.type = "text/plain";
		elem.width = "1024";
		elem.height = "768";
		elem.src = getSourceString(frame);
		elem.className = "frame";
		dialog.appendChild(elem);
	}
}

import Dialog from "./Dialog";

export function initElement(element: ElementInit | HTMLElement) {
	if (element instanceof HTMLElement) 
		return element;
	
	const elem = document.createElement(element.tagName || "div");
	elem.className = element.className || "row";

	const innerHTML = element.innerHTML;
	if (innerHTML != null)
		elem.innerHTML = innerHTML;

	const innerText = element.innerText;
	if (innerText != null)
		elem.textContent = innerText;

	const style = element.style;
	if (style != null)
		elem.setAttribute("style", style);

	const attrs = element.attributes;
	if (attrs != null) {
		for (let attr in attrs) {
			elem.setAttribute(attr, attrs[attr]);
		}
	}

	return elem;
}

export default class AlertDialog extends Dialog implements globalThis.AlertDialog {
	declare readonly message: string | nul;
	declare readonly elements: AlertDialogElements | nul;
	declare readonly buttons: AlertDialogButtons | nul;

	constructor(init?: AlertDialogInit) {
		super(init);
	}

	/** @Override */
	protected async initializeBody(body: HTMLElement, win: Window, doc: Document) {
		const message = this.message;
		if (message != null) {
			const elem = doc.createElement("div");
			elem.className = "row";
			elem.textContent = message;
			body.appendChild(elem);
		}

		for (let elem of (this.elements || [])) {
			body.appendChild(initElement(elem));
		}

		const buttons = this.buttons;
		if (buttons != null) {
			const row = doc.createElement("div");
			row.className = "row-center";

			for (let button of buttons) {
				const elem = doc.createElement("button");
				elem.textContent = button.text || "";
				elem.disabled = button.disabled || false;
				elem.onclick = button.click || null;
				row.appendChild(elem);
			}

			body.appendChild(row);
		}
	}
}
